import React, { FunctionComponent } from 'react';
import { IValidationError } from '@ngt/forms';
import { Typography } from '@mui/material';
import { IInputRenderProps, useScopedField } from '@ngt/forms-core';

const subscription = { value: true };

const ValueWithSpecificationDisplay: FunctionComponent<IInputRenderProps<string, IValidationError> & { specificationName: string; }> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { ...restInputActions } },
    specificationName,
    ...rest
}) => {

    const { state: { value: specificationValue } } = useScopedField<string, IValidationError>(specificationName, subscription, false);

    const calculatedSpecificationValue = specificationValue ? <> &ndash; {specificationValue}</> : null;

    return (
        <Typography>
            {value}{calculatedSpecificationValue}
        </Typography>
    )
};

export default ValueWithSpecificationDisplay;
