import { Accordion, AccordionDetails, AccordionSummary, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, IGroupedSubformGridCell, IUpdatedFormPropertyStyler } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { AdjuvantTherapy, NeoadjuvantTherapy } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { useFieldState, useScopedField } from '@ngt/forms-core';
import { InputOnlyField } from '@ngt/forms-pi-signoff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IChariotTherapyProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = (value: string) => value ? <>Not Administered &ndash; {value}</> : 'Not Administered';
const booleanAccessor = (value: boolean) => value === true ? 'Yes' : value === false ? 'No' : null;

const neoadjuvantTherapyNivolumabColumns: IGroupedSubformGridCell<NeoadjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'nivolumabAdministered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="nivolumabDateAdministered"
                elseName="nivolumabAdministeredSpecify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabDateAdministered'
                },
                {
                    name: 'nivolumabAdministeredSpecify'
                }
            ]
        }
    },
    {
        name: 'nivolumabActualDose',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="nivolumab" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <InputOnlyField
                name="nivolumab"
                component={ExpectedDoseDisplay}
                unitsName="nivolumabUnits"
                FieldProps={{
                    prependParentName: false
                }}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabUnits',
                    prependParentPath: false
                }
            ]
        }
    },
    {
        name: 'nivolumabLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="nivolumabLastCycleSpecify"
                reasonName="nivolumabLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabLastCycleSpecify'
                },
                {
                    name: 'nivolumabLastCycleReason'
                }
            ]
        }
    }
];

const paclitaxelDoseAccessor = (value: number) => value !== null && value !== undefined ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> mg</Typography></> : null;

const neoadjuvantTherapyPaclitaxelColumns: IGroupedSubformGridCell<NeoadjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'paclitaxelActualDoseDay1',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="paclitaxelActualDoseDay1Value"
                elseName="paclitaxelActualDoseDay1Specify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={paclitaxelDoseAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'paclitaxelActualDoseDay1Value'
                },
                {
                    name: 'paclitaxelActualDoseDay1Specify'
                }
            ]
        }
    },
    {
        name: 'paclitaxelActualDoseDay8',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="paclitaxelActualDoseDay8Value"
                elseName="paclitaxelActualDoseDay8Specify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={paclitaxelDoseAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'paclitaxelActualDoseDay8Value'
                },
                {
                    name: 'paclitaxelActualDoseDay8Specify'
                }
            ]
        }
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="paclitaxelDuBois" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <InputOnlyField
                name="paclitaxelDuBois"
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
                FieldProps={{
                    prependParentName: false
                }}
            />
        )
    },
    {
        name: 'paclitaxelLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="paclitaxelLastCycleSpecify"
                reasonName="paclitaxelLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'paclitaxelLastCycleReason'
                },
                {
                    name: 'paclitaxelLastCycleSpecify'
                }
            ]
        }
    }
];

const ipilimumabSubscription = {

};

const ipilimumabParentSubscription = {
    value: true
};

const ipilimumabHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'ipilimumabUnits'
        }
    ]
}

const IpilimumabExpectedDose = () => {
    const { name } = useFieldState(ipilimumabSubscription);

    const parentName = getParentPropertyPath(name);

    const { state: { value: cycle } } = useScopedField(`${parentName}.cycle`, ipilimumabParentSubscription);

    if (cycle === 'Cycle One' || cycle === 'Cycle Four') {
        return (
            <InputOnlyField
                name="ipilimumab"
                component={ExpectedDoseDisplay}
                unitsName="ipilimumabUnits"
                FieldProps={{
                    prependParentName: false
                }}
                UpdatedFormPropertyStylerProps={ipilimumabHighlighting}
            />
        );
    }

    return (
        <Typography>
            0<Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
        </Typography>
    );

};

const neoadjuvantTherapyIpilimumabColumns: IGroupedSubformGridCell<NeoadjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,

        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'ipilimumabActualDose',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="ipilimumabActualDoseValue"
                elseName="ipilimumabActualDoseSpecify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={paclitaxelDoseAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ipilimumabActualDoseValue'
                },
                {
                    name: 'ipilimumabActualDoseSpecify'
                }
            ]
        }
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="ipilimumab" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <IpilimumabExpectedDose />
        )
    },
    {
        name: 'ipilimumabLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="ipilimumabLastCycleSpecify"
                reasonName="ipilimumabLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ipilimumabLastCycleSpecify'
                },
                {
                    name: 'ipilimumabLastCycleReason'
                }
            ]
        }
    }
];

const adjuvantTherapyColumns: IGroupedSubformGridCell<AdjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'adjuvantAdministered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="adjuvantDateAdministered"
                elseName="adjuvantAdministeredSpecify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'adjuvantDateAdministered'
                },
                {
                    name: 'adjuvantAdministeredSpecify'
                }
            ]
        }
    },
    {
        name: 'adjuvantActualDoseValue',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="nivolumabAdjuvant" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <InputOnlyField
                name="nivolumabAdjuvant"
                component={ExpectedDoseDisplay}
                unitsName="nivolumabUnits"
                FieldProps={{
                    prependParentName: false
                }}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabUnits',
                    prependParentPath: false
                }
            ]
        }
    },
    {
        name: 'adjuvantLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="adjuvantLastCycleSpecify"
                reasonName="adjuvantLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'adjuvantLastCycleSpecify'
                },
                {
                    name: 'adjuvantLastCycleReason'
                }
            ]
        }
    }
];

const ChariotTherapy: FunctionComponent<IChariotTherapyProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Chariot Therapy
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <Typography variant="h2" className={classes.heading}>
                        Neoadjuvant Therapy
                    </Typography>
                    <Typography variant="h3" className={classes.subheading}>
                        Nivolumab
                    </Typography>
                    <GroupedSubformGrid
                        type={NeoadjuvantTherapy}
                        name="neoadjuvantTherapies"
                        columns={neoadjuvantTherapyNivolumabColumns}
                        disabled
                    />
                    <Typography variant="h3" className={classes.subheading}>
                        Paclitaxel
                    </Typography>
                    <GroupedSubformGrid
                        type={NeoadjuvantTherapy}
                        name="neoadjuvantTherapies"
                        columns={neoadjuvantTherapyPaclitaxelColumns}
                        disabled
                    />
                    <Typography variant="h3" className={classes.subheading}>
                        Ipilimumab
                    </Typography>
                    <GroupedSubformGrid
                        type={NeoadjuvantTherapy}
                        name="neoadjuvantTherapies"
                        columns={neoadjuvantTherapyIpilimumabColumns}
                        disabled
                    />
                    <Typography variant="h2" className={classes.heading}>
                        Adjuvant Therapy
                    </Typography>
                    <Typography variant="h3" className={classes.subheading}>
                        Nivolumab
                    </Typography>
                    <GroupedSubformGrid
                        type={AdjuvantTherapy}
                        name="adjuvantTherapies"
                        columns={adjuvantTherapyColumns}
                        disabled
                    />
                </AccordionDetails>
            </Accordion>
                
                
        </>
    );
};

export default ChariotTherapy;
