import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateDisplay, FieldProvider, FormLabel, GroupedFields, IGroupedFieldStyleProps, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import React, { FunctionComponent } from 'react';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface ITreatmentExposureSummaryProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        color: 'white'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap'
        }
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column'
    },
    header: {
        '& tr > th > p': {
            fontWeight: 600
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const TreatmentExposureSummary: FunctionComponent<ITreatmentExposureSummaryProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white"/>}
                    className={classes.accordionSummary}
                    
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Treatment Exposure Summary
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <Table>
                        <TableHead className={classes.header}>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="chemotherapyAdministeredType">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureNacIpi">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureNacNivo">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell style={{ fontWeight: 700 }}>
                                    <FieldProvider name="treatmentExposurePaclitaxel">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureSurgery">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureANivo">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <InputOnlyField
                                        name="chemotherapyAdministeredType"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureNacIpi"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureNacNivo"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposurePaclitaxel"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureSurgery"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureANivo"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            BCS = Breast Conserving Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M = Mastectomy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M+R = Mastectomy + Reconstruction;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            OS = Oncoplastic Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            SLNB = Sentinel Lymph Node Biopsy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            S-SM = Skin Sparing Mastectomy;
                        </Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default TreatmentExposureSummary;
