import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ITableTextResizerProps {
    fontSize: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    gridSize: {
        fontSize: (props: ITableTextResizerProps) => `${props.fontSize} !important`,

        '& .MuiTypography-root': {
            fontSize: (props: ITableTextResizerProps) => `${props.fontSize} !important`,
        },
        '& .MuiTableHead-root': {
            background: 'white !important',
        }
    }
}));

const TableTextResizer: FunctionComponent<ITableTextResizerProps> = (props) => {
    const classes = useStyles(props)

    return (
        <div className={classes.gridSize}>
            {props.children}
        </div>
    );
};

export default TableTextResizer;
