/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { FieldContext, IInputRenderProps, useScopedField } from '@ngt/forms-core';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { getParentPropertyPath, IValidationError } from '@ngt/forms';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = IInputRenderProps<number, IValidationError>

export interface IExpectedDoseDisplayProps extends TextFieldsProps {
    unitsName: string;
    decimalPlaces?: number;
    unitsPrependParentName?: boolean;
    unitsTableCell?: boolean;
    nullDisplay?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const subscription = { value: true };

const ExpectedDoseDisplay: React.FunctionComponent<IExpectedDoseDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    decimalPlaces,
    unitsName,
    unitsPrependParentName,
    unitsTableCell,
    nullDisplay,
    ...rest
}) => {
    const parentContext = React.useContext(FieldContext);

    const computedUnitsName = React.useMemo(() => {
        if (parentContext?.name && unitsPrependParentName === true) {
            const parentName = unitsTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (unitsName.startsWith('[')) {
                return `${parentName}${unitsName}`;
            }

            return `${parentName}.${unitsName}`;
        }

        return unitsName;
    }, [parentContext?.name, unitsName, unitsPrependParentName, unitsTableCell]);

    const { state: { value: unitValue } } = useScopedField<string, IValidationError>(computedUnitsName, subscription, false);

    if (value === null || value === undefined) {
        return (
            <Typography>
                {nullDisplay ?? 'Not Done'}
            </Typography>
        );
    }

    const convertedUnits = unitValue === 'mg/m2' ? <> mg/m<sup>2</sup></> : unitValue
    const convertedValue = decimalPlaces ? value.toFixed(decimalPlaces) : value;

    return (
        <Typography>
            {convertedValue}<Typography color="textSecondary" variant="caption" component="span"> {convertedUnits}</Typography>
        </Typography>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ExpectedDoseDisplay;
