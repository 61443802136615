import { Accordion, AccordionDetails, AccordionSummary, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { Condition, ContextFormTitle, ContextPISignOffForm, GroupedField, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { ParticipantStudySummary as SurgeryDto, SlnbResult, SurgeryType } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { ICondition } from '../../../../../../NGT.Library.ServiceStack.PISignOff/Source/forms-pi-signoff/dist/components/form/Condition';
import ValueWithSpecificationDisplay from '../../components/form/input/display/ValueWithSpecificationDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface ISurgeryAndHistopathologyOptionsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasSurgery: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryCompleted && !!value?.surgeryTypes && value?.surgeryTypes.length > 0;

const slnbPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.slnb;
const slnbNotPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.slnb;

const dissectionPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.axillaryDissectionPerformed;
const dissectionNotPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.axillaryDissectionPerformed;

const conditionSubscription = { value: true };

const surgeryTypeColumns: IGroupedSubformGridCell<SurgeryType>[] = [
    {
        name: 'type',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'date',
        width: '60%' as any,
        content: (
            <Input component={DateDisplay} />
        )
    }
];

const slnbResultsColumns: IGroupedSubformGridCell<SlnbResult>[] = [
    {
        name: 'nodeNumber',
        content: (
            <Input component={NumericDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'hot',
        content: (
            <Input component={BooleanDisplay} />
        )
    },
    {
        name: 'blue',
        content: (
            <Input component={BooleanDisplay} />
        )
    },
    {
        name: 'tumourInvolvement',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const SurgeryAndHistopathology: FunctionComponent<ISurgeryAndHistopathologyOptionsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Surgery & Histopathology
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields
                        color="None"
                    >
                        <GroupedField
                            name="surgeryCompleted"
                            component={BooleanDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={SurgeryDto}
                            condition={hasSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={SurgeryType}
                                name="surgeryTypes"
                                columns={surgeryTypeColumns}
                                disabled
                            />
                        </Condition>
                        <Typography variant="h3" className={classes.heading}>
                            Sentinel Lymph Node Biopsy
                        </Typography>
                        <Condition
                            type={SurgeryDto}
                            condition={slnbPerformed}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="slnbDate"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="slnbRemoved"
                                component={NumericDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="nonSlnbRemoved"
                                component={NumericDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="injectionType"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />

                            <GroupedSubformGrid
                                type={SlnbResult}
                                name="slnbResults"
                                columns={slnbResultsColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={SurgeryDto}
                            condition={slnbNotPerformed}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="slnb"
                                component={BooleanDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                        <Typography variant="h3" className={classes.heading}>
                            Axillary Dissection
                        </Typography>
                        <Condition
                            type={SurgeryDto}
                            condition={dissectionPerformed}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="axillaryDissectionPerformedDate"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="nodesRemoved"
                                component={NumericDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="positiveNodes"
                                component={NumericDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="axillaryRTPlanned"
                                component={BooleanDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                        <Condition
                            type={SurgeryDto}
                            condition={dissectionNotPerformed}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="axillaryDissectionPerformed"
                                component={BooleanDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>

                        <Typography variant="h3" className={classes.heading}>
                            Surgical Pathology - residual disease following neoadjuvant chemotherapy
                        </Typography>

                        <Typography variant="h4" className={classes.subheading}>
                            Tumour
                        </Typography>
                        <GroupedField
                            name="histoPathologicType"
                            component={ValueWithSpecificationDisplay}
                            specificationName="histoPathologicTypeSpecify"
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="histologicalGrade"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="lymphovascularInvasion"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="focalityCentricity"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="radialMarginsInvasive"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="radialMarginsInsitu"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="til"
                            component={NumericDisplay}
                            suffix={
                                <Typography component="span">%</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />

                        <Typography variant="h4" className={classes.subheading}>
                            Residual Cancel Burden
                        </Typography>
                        <GroupedField
                            name="primaryTumourDimension1"
                            component={NumericDisplay}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="primaryTumourDimension2"
                            component={NumericDisplay}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="cancerCellularity"
                            component={NumericDisplay}
                            suffix={
                                <Typography component="span">%</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="percentageInsitu"
                            component={NumericDisplay}
                            suffix={
                                <Typography component="span">%</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="positiveLymphNodes"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="largestMetastasisDiameter"
                            component={NumericDisplay}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SurgeryAndHistopathology;
