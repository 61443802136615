import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, InputOnlyField, NumericDisplay, SingleLineTextDisplay, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, GroupedField, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { AdjuvantTherapy, ClinicalTumourMeasurement, NeoadjuvantTherapy, RadiologicalTumourMeasurement } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { FormContext, useFieldState, useScopedField } from '@ngt/forms-core';
import CoalesceDisplay from '../../components/form/input/display/CoalesceDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface ITumourMeasurementsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        color: 'white'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            whiteSpace: 'nowrap'
        },
        fontStyle: 'italic',
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    gridStyle: {
        '& > div > table > thead > tr > th > div > p': {
            fontWeight: '600'
        },
        '& > div > table > tbody > tr': {
            '&:nth-of-type(8n+1)': {
                backgroundColor: "#eee0ff"
            },
            '&:nth-of-type(8n+3)': {
                backgroundColor: "#eee0ff"
            },

        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { values: true };

const longestDiameterBoolAccessor = (value: boolean) => value ? "Not Measurable" : null;
const longestDiameterDataAccessor = (value: string) => value ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> mm</Typography></> : null;

const clinicalTumourMeasurementsColumns: IGroupedSubformGridCell<ClinicalTumourMeasurement>[] = [
    {
        name: 'breastMassNumber',
        content: (
            <Input component={TextDisplay} />
        ),
        maxWidth: 100
    },
    {
        name: 'category',
        content: (
            <Input component={TextDisplay} />
        ),
        maxWidth: 80
    },
    {
        name: 'cycle1LongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="cycle1LongestDiameter"
                    ifNullAccessor={longestDiameterBoolAccessor}
                    accessor={longestDiameterDataAccessor}
                    ifNullPrependParentName
                    ifNullTableCell
                />
                
                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="cycle1EvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        ),
        width: 100
    },
    {
        name: 'cycle2LongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="cycle2LongestDiameter"
                    ifNullPrependParentName
                    ifNullTableCell
                />
                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="cycle2EvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        ),
        width: 100
    },
    {
        name: 'cycle3LongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="cycle3LongestDiameter"
                    ifNullAccessor={longestDiameterBoolAccessor}
                    accessor={longestDiameterDataAccessor}
                    ifNullPrependParentName
                    ifNullTableCell
                />

                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="cycle3EvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        ),
        width: 100
    },
    {
        name: 'cycle4LongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="cycle4LongestDiameter"
                    ifNullAccessor={longestDiameterBoolAccessor}
                    accessor={longestDiameterDataAccessor}
                    ifNullPrependParentName
                    ifNullTableCell
                />
                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="cycle4EvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        ),
        width: 100
    },
    {
        name: 'cycle5LongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="cycle5LongestDiameter"
                    ifNullAccessor={longestDiameterBoolAccessor}
                    accessor={longestDiameterDataAccessor}
                    ifNullPrependParentName
                    ifNullTableCell
                />
                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="cycle5EvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        ),
        width: 100
    },
    {
        name: 'cycle6LongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="cycle6LongestDiameter"
                    ifNullAccessor={longestDiameterBoolAccessor}
                    accessor={longestDiameterDataAccessor}
                    ifNullPrependParentName
                    ifNullTableCell
                />
                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="cycle6EvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        ),
        width: 100
    },
    {
        name: 'endOfChemoLongestDiameterValue',
        content: (
            <>
                <Input
                    component={CoalesceDisplay}
                    ifNullName="endOfChemoLongestDiameter"
                    ifNullAccessor={longestDiameterBoolAccessor}
                    accessor={longestDiameterDataAccessor}
                    ifNullPrependParentName
                    ifNullTableCell
                />
                <Input
                    component={IfElseDisplay}
                    ifName=""
                    elseName="endOfChemoEvidenceOfProgression"
                    ifPrependParentName
                    ifTableCell
                    elsePrependParentName
                    elseTableCell
                />
            </>
        )
    }
];

const radiologicalTumourMeasurementsColumns: IGroupedSubformGridCell<RadiologicalTumourMeasurement>[] = [
    {
        name: 'breastLesionNumber',
        content: (
            <Input component={TextDisplay} />
        ),
        maxWidth: 80
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        ),
        maxWidth: 65
    },
    {
        name: 'dimension',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'diagnostic',
        content: (
            <Input component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'postAnthracycline',
        content: (
            <Input component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'postNeoadjuvantChemotherapy',
        content: (
            <Input component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        ),
        maxWidth: 140
    },
    {
        name: 'postNeoadjuvantChemotherapyResponse',
        content: (
            <Input component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        ),
        maxWidth: 200
    }
]

const dataAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const boolAccessor = (value: boolean) => value === true ? 'Not Done' : null;

const TumourMeasurements: FunctionComponent<ITumourMeasurementsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}

                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Tumour Measurements
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <Typography variant="h3" className={classes.heading}>
                        Clinical Tumour Measurements
                    </Typography>
                    <div className={classes.gridStyle}>
                        <GroupedSubformGrid
                            type={ClinicalTumourMeasurement}
                            name="clinicalTumourMeasurements"
                            columns={clinicalTumourMeasurementsColumns}
                            disabled
                        />
                    </div>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            If any of the above fields are blank, the assessment was not completed at the specified timepoint.
                            <br />
                            Note: protocol only required at Cycle 3, 6 and End of Chemotherapy
                        </Typography>
                    </div>
                    <Typography variant="h3" className={classes.heading}>
                        Radiological Tumour Measurements
                    </Typography>
                    <div className={classes.gridStyle}>
                        <GroupedSubformGrid
                            type={RadiologicalTumourMeasurement}
                            name="radiologicalTumourMeasurements"
                            columns={radiologicalTumourMeasurementsColumns}
                            disabled
                        />
                    </div>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            If any of the above fields are blank, the assessment was not completed at the specified timepoint.
                        </Typography>
                    </div>
                    <div>
                    <Typography variant="h3" className={classes.heading}>
                        Axilla Assessment
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>

                                </TableCell>
                                <TableCell width="250">
                                    <FieldProvider name="diagnosticAxillaryNodesAssessmentDate">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell width="250">
                                    <FieldProvider name="postAnthracyclineAxillaryNodesAssessmentDate">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell width="350">
                                    <FieldProvider name="postNeoadjuvantAxillaryNodesAssessmentDate">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        Axillary nodes radiologically assessed?
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="diagnosticAxillaryNodesAssessmentNotDone"
                                        component={CoalesceDisplay}
                                        ifNullName="diagnosticAxillaryNodesAssessmentDate"
                                        ifNullAccessor={dataAccessor}
                                        accessor={boolAccessor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="postAnthracyclineAxillaryNodesAssessmentNotDone"
                                        component={CoalesceDisplay}
                                        ifNullName="postAnthracyclineAxillaryNodesAssessmentDate"
                                        ifNullAccessor={dataAccessor}
                                        accessor={boolAccessor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="postNeoadjuvantAxillaryNodesAssessmentNotDone"
                                        component={CoalesceDisplay}
                                        ifNullName="postNeoadjuvantAxillaryNodesAssessmentDate"
                                        ifNullAccessor={dataAccessor}
                                        accessor={boolAccessor}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        Suspicious Axillary Nodes Identified?
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="diagnosticSuspisciousAxillary"
                                        component={SingleLineTextDisplay}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>
                                        }
 
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="postAnthracyclineSuspisciousAxillary"
                                        component={SingleLineTextDisplay}
                                        suffix={
                                            <Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="postNeoadjuvantChemoSuspisciousAxillary"
                                        component={BooleanDisplay}
                                        convert={(value) => { return (value > 0 ? true : false) }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow
                                style={{ backgroundColor: "#eee0ff" }}
                            >
                                <TableCell>
                                    <Typography>
                                        Cytological Assessment
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="diagnosticCytologicalAssessment"
                                        component={CoalesceDisplay}
                                        ifNullName="cytologicalAssessmentDate"
                                        ifNullAccessor={dataAccessor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="postAnthracyclineCytologicalAssessment"
                                        component={CoalesceDisplay}
                                        ifNullName="cytologicalAssessmentDate"
                                        ifNullAccessor={dataAccessor}
                                    />

                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="postNeoadjuvantCytologicalAssessment"
                                        component={CoalesceDisplay}
                                        ifNullName="cytologicalAssessmentDate"
                                        ifNullAccessor={dataAccessor}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </div>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            If any of the above fields are blank, the assessment was not completed at the specified timepoint.
                        </Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default TumourMeasurements;
