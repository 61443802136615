import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateDisplay, FieldProvider, FormLabel, GroupedFields, IGroupedFieldStyleProps, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPatientInformation, ContextPISignOffForm, ContextPISignOffFormBreadcrumbs, ContextFormQueryGrid, InputOnlyField, GroupedField, ContextFormDataDate } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { ConcomitantMedication } from '../../apis/dtos';
import AdverseEvents from './AdverseEvents';
import ChariotTherapy from './ChariotTherapy';
import ConcomitantMedications from './ConcomitantMedications';
import SurgeryAndHistopathology from './SurgeryAndHistopathology';
import SurgeryOptions from './SurgeryOptions';
import SurvivalFollowUpAndRecurrence from './SurvivalFollowUpAndRecurrence';
import TreatmentExposureSummary from './TreatmentExposureSummary';
import TumourMeasurements from './TumourMeasurements';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IParticipantStudySummaryProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0, 2)
    },
    subtitle: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
}));



const ParticipantStudySummary: FunctionComponent<IParticipantStudySummaryProps> = () => {
    const classes = useStyles();

    return (
        <>
            <ContextPISignOffFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <Typography variant="h1" className={classes.title}>
                <ContextFormTitle />
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
                <ContextFormDataDate />
            </Typography>
            <ContextPISignOffForm
                queriesName='queries'
            >
                <TreatmentExposureSummary />
                <ChariotTherapy />
                <TumourMeasurements />
                <SurgeryOptions />
                <SurgeryAndHistopathology />
                <AdverseEvents />
                <ConcomitantMedications />
                <SurvivalFollowUpAndRecurrence />

                <ContextFormQueryGrid name="queries" />
            </ContextPISignOffForm>
        </>
    );
};

export default ParticipantStudySummary;
