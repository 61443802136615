import { Accordion, AccordionDetails, AccordionSummary, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { Condition, ContextFormTitle, ContextPISignOffForm, ICondition, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { ConcomitantMedication, ParticipantStudySummary as ConcomitantMedicationsDto } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IConcomitantMedicationsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    alert: {
        padding: theme.spacing(2)
    },
    subtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const groupStyleProps: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
};

const hasNoMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.concomitantMedications || value?.concomitantMedications?.length === 0;
const hasMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.concomitantMedications && value?.concomitantMedications?.length > 0;

const conditionSubscription = { value: true };

const concomitantMedicationColumns: IGroupedSubformGridCell<ConcomitantMedication>[] = [
    {
        name: 'agentName',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'indication',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dateStarted',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'dateStopped',
        content: (
            <Input component={DateDisplay} />
        )
    }
];

const ConcomitantMedications: FunctionComponent<IConcomitantMedicationsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Concomitant Medications
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasMedications}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ConcomitantMedication}
                            name="concomitantMedications"
                            columns={concomitantMedicationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={ConcomitantMedicationsDto}
                        condition={hasNoMedications}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Concomitant Medications"
                                description="There is no Concomitant Medications associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ConcomitantMedications;
