import { Accordion, AccordionDetails, AccordionSummary, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BasicAlert } from '@ngt/forms-trials';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, InputOnlyField, NumericDisplay, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, GroupedField, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { AdjuvantTherapy, NeoadjuvantTherapy } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { useFieldState, useScopedField } from '@ngt/forms-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface ISurgeryOptionsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const SurgeryOptions: FunctionComponent<ISurgeryOptionsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Surgery Options
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields
                        color="None"
                    >
                        <Typography variant="h3" className={classes.heading}>
                            Post-Anthracycline assessment
                        </Typography>
                        <GroupedField
                            name="assessmentOfClinicalResponse"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="surgicalOptions"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Typography variant="h3" className={classes.heading}>
                            Post Neo-Adjuvant Chemotherapy assessment
                        </Typography>
                        <GroupedField
                            name="surgeryRequired"
                            component={BooleanDisplay}
                            falseText="No (mastectomy required)"
                            GroupStyleProps={groupStyleProps}
                        />
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
            
        </>
    );
};

export default SurgeryOptions;
