import { Accordion, AccordionDetails, AccordionSummary, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { AdverseEvent, ParticipantStudySummary as AdverseEventsDto } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import TableTextResizer from '../../components/form/TableTextResizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface IAdverseEventsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    alert: {
        padding: theme.spacing(2)
    },
    subtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const groupStyleProps: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
};

const adverseEventColumns: IGroupedSubformGridCell<AdverseEvent>[] = [
    {
        name: 'systemsOrganClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.systemsOrganClass
    },
    {
        name: 'aeTerm',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="aeTermSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'aeTermSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.aeTerm ? `${row?.aeTerm} - ${row?.aeTermSpecify}` : undefined
    },
    {
        name: 'grade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.grade
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'resolution',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.resolution
    },
    {
        name: 'consequence',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.consequence
    },
    {
        name: 'relationshipToTreatment',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.relationshipToTreatment
    },
    {
        name: 'actionTakenPaclitaxel',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenPaclitaxel
    },
    {
        name: 'actionTakenNivolumab',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenNivolumab
    },
    {
        name: 'actionTakenIpilimumab',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenIpilimumab
    },
    {
        name: 'imGiven',
        content: (
            <Input
                component={BooleanDisplay}
            />
        ),
        sortValue: (row) => row?.imGiven
    },
    {
        name: 'treatmentGiven',
        content: (
            <Input
                component={BooleanDisplay}
            />
        ),
        sortValue: (row) => row?.treatmentGiven
    }
];

const hasNoAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.adverseEvents || value?.adverseEvents?.length === 0;
const hasAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.adverseEvents && value?.adverseEvents?.length > 0;

const conditionSubscription = { value: true };

const AdverseEvents: FunctionComponent<IAdverseEventsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Adverse Events
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasAdverseEvents}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <TableTextResizer fontSize="0.750rem">
                            <GroupedSubformGrid
                                type={AdverseEvent}
                                name="adverseEvents"
                                columns={adverseEventColumns}
                                disabled
                                stickHeader
                            />
                        </TableTextResizer>
                    </Condition>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasNoAdverseEvents}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Adverse Events"
                                description="There is no Adverse Events associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default AdverseEvents;
