/* Options:
Date: 2022-01-21 10:43:29
Version: 5.132
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44305

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export class NativeTypesBase
{
    public baseUrl: string;
    public makePartial?: boolean;
    public makeVirtual?: boolean;
    public makeInternal?: boolean;
    public addReturnMarker?: boolean;
    public addDescriptionAsComments?: boolean;
    public addDataContractAttributes?: boolean;
    public makeDataContractsExtensible?: boolean;
    public addIndexesToDataMembers?: boolean;
    public addGeneratedCodeAttributes?: boolean;
    public initializeCollections?: boolean;
    public addImplicitVersion?: number;
    public addResponseStatus?: boolean;
    public addServiceStackTypes?: boolean;
    public addModelExtensions?: boolean;
    public addPropertyAccessors?: boolean;
    public excludeGenericBaseTypes?: boolean;
    public settersReturnThis?: boolean;
    public makePropertiesOptional?: boolean;
    public exportAsTypes?: boolean;
    public exportValueTypes?: boolean;
    public excludeNamespace?: boolean;
    public addDefaultXmlNamespace: string;
    public globalNamespace: string;
    public baseClass: string;
    public package: string;
    public dataClass: string;
    public dataClassJson: string;
    public addNamespaces: string[];
    public defaultNamespaces: string[];
    public defaultImports: string[];
    public includeTypes: string[];
    public excludeTypes: string[];
    public treatTypesAsStrings: string[];

    public constructor(init?: Partial<NativeTypesBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class PISignOffBatchCreationOptions
{
    public formDefinitionIds: number[];
    public completeUnchangedForms?: boolean;
    public compareFormsAcrossPISignOffDefinitions?: boolean;

    public constructor(init?: Partial<PISignOffBatchCreationOptions>) { (Object as any).assign(this, init); }
}

export interface IUserGetSingle
{
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum PISignOffStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
    Pending = 5,
    Failed = 5,
}

export class AdjuvantTherapy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public cycle: string;
    public adjuvantAdministered?: boolean;
    public adjuvantDateAdministered?: string;
    public adjuvantAdministeredSpecify: string;
    public adjuvantActualDose?: boolean;
    public adjuvantActualDoseValue?: number;
    public adjuvantLastCycle?: boolean;
    public adjuvantLastCycleReason: string;
    public adjuvantLastCycleSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdjuvantTherapy>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByPISignOffIdAndRepeat extends IGet
{
    piSignOffId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class AdverseEvent implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public institution: string;
    public event: string;
    // @Ignore()
    public eventNumber?: number;

    public systemsOrganClass: string;
    public aeTermId?: number;
    public aeTerm: string;
    public aeTermSpecify: string;
    public grade?: number;
    public onset?: string;
    public resolution?: string;
    public consequence: string;
    public relationshipToTreatment: string;
    public actionTakenPaclitaxel: string;
    public actionTakenNivolumab: string;
    public actionTakenIpilimumab: string;
    public imGiven?: boolean;
    public treatmentGiven?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvent>) { (Object as any).assign(this, init); }
}

export class AntiCancerTreatment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public treatment: string;
    public treatmentSpecify: string;
    public commencementDate?: string;
    public completedDate?: string;
    public ongoing?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AntiCancerTreatment>) { (Object as any).assign(this, init); }
}

export class AntiCancerTreatment36Month implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public treatment: string;
    public treatmentSpecify: string;
    public commencementDate?: string;
    public completedDate?: string;
    public ongoing?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AntiCancerTreatment36Month>) { (Object as any).assign(this, init); }
}

export class BreastCancerRecurrence implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public type: string;
    public location: string;
    public dateSuspicion?: string;
    public dateConfirmation?: string;
    public timeOnStudy?: number;
    public typeOfConfirmation: string;
    public typeOfConfirmationSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastCancerRecurrence>) { (Object as any).assign(this, init); }
}

export class ClinicalTumourMeasurement implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public breastMassNumber?: number;
    public category: string;
    public cycle1LongestDiameter?: boolean;
    public cycle1LongestDiameterValue?: number;
    public cycle1EvidenceOfProgression: string;
    public cycle2LongestDiameter?: boolean;
    public cycle2LongestDiameterValue?: number;
    public cycle2EvidenceOfProgression: string;
    public cycle3LongestDiameter?: boolean;
    public cycle3LongestDiameterValue?: number;
    public cycle3EvidenceOfProgression: string;
    public cycle4LongestDiameter?: boolean;
    public cycle4LongestDiameterValue?: number;
    public cycle4EvidenceOfProgression: string;
    public cycle5LongestDiameter?: boolean;
    public cycle5LongestDiameterValue?: number;
    public cycle5EvidenceOfProgression: string;
    public cycle6LongestDiameter?: boolean;
    public cycle6LongestDiameterValue?: number;
    public cycle6EvidenceOfProgression: string;
    public endOfChemoLongestDiameter?: boolean;
    public endOfChemoLongestDiameterValue?: number;
    public endOfChemoEvidenceOfProgression: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ClinicalTumourMeasurement>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public agentName: string;
    public indication: string;
    public dateStarted?: string;
    public dateStopped?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class NeoadjuvantTherapy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public cycle: string;
    public nivolumabAdministered?: boolean;
    public nivolumabDateAdministered?: string;
    public nivolumabAdministeredSpecify: string;
    public nivolumabActualDose?: number;
    public nivolumabUnits: string;
    public nivolumabLastCycle?: boolean;
    public nivolumabLastCycleReason: string;
    public nivolumabLastCycleSpecify: string;
    public paclitaxelUnits: string;
    public paclitaxelActualDoseDay1?: boolean;
    public paclitaxelActualDoseDay1Value?: number;
    public paclitaxelActualDoseDay1Specify: string;
    public paclitaxelActualDoseDay8?: boolean;
    public paclitaxelActualDoseDay8Value?: number;
    public paclitaxelActualDoseDay8Specify: string;
    public paclitaxelLastCycle?: boolean;
    public paclitaxelLastCycleReason: string;
    public paclitaxelLastCycleSpecify: string;
    public ipilimumabActualDose?: boolean;
    public ipilimumabActualDoseValue?: number;
    public ipilimumabActualDoseSpecify: string;
    public ipilimumabLastCycle?: boolean;
    public ipilimumabLastCycleReason: string;
    public ipilimumabLastCycleSpecify: string;
    public ipilimumabUnits: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NeoadjuvantTherapy>) { (Object as any).assign(this, init); }
}

export class RadiologicalTumourMeasurement implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public breastLesionNumber: string;
    public location: string;
    public dimension?: number;
    public diagnostic?: number;
    public postAnthracycline?: number;
    public postNeoadjuvantChemotherapy?: number;
    public postNeoadjuvantChemotherapyResponse: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<RadiologicalTumourMeasurement>) { (Object as any).assign(this, init); }
}

export class SlnbResult implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public nodeNumber?: number;
    public location: string;
    public hot?: boolean;
    public blue?: boolean;
    public tumourInvolvement: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SlnbResult>) { (Object as any).assign(this, init); }
}

export class SurgeryType implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public type: string;
    public date?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurgeryType>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public piSignOffId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    public repeat?: number;
    public question: string;
    public response: string;
    public status?: QueryStatus;
    public comment?: string;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class ParticipantStudySummary implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public completeDate?: string;
    public cancelDate?: string;
    public dataDate?: string;
    public chemotherapyAdministeredType: string;
    public chemotherapyAdministeredTypeOther: string;
    public treatmentExposureNacIpi?: number;
    public treatmentExposureNacNivo?: number;
    public treatmentExposurePaclitaxel?: number;
    public treatmentExposureSurgery: string;
    public treatmentExposureANivo?: number;
    public nivolumab?: number;
    public nivolumabAdjuvant?: number;
    public nivolumabUnits: string;
    public paclitaxelMosteller?: number;
    public paclitaxelMostellerUnits: string;
    public paclitaxelDuBois?: number;
    public paclitaxelDuBoisUnits: string;
    public ipilimumab?: number;
    public ipilimumabUnits: string;
    // @Ignore()
    public neoadjuvantTherapies: NeoadjuvantTherapy[];

    // @Ignore()
    public adjuvantTherapies: AdjuvantTherapy[];

    // @Ignore()
    public clinicalTumourMeasurements: ClinicalTumourMeasurement[];

    // @Ignore()
    public radiologicalTumourMeasurements: RadiologicalTumourMeasurement[];

    public diagnosticAxillaryNodesAssessmentNotDone?: boolean;
    public diagnosticAxillaryNodesAssessmentDate?: string;
    public postAnthracyclineAxillaryNodesAssessmentNotDone?: boolean;
    public postAnthracyclineAxillaryNodesAssessmentDate?: string;
    public postNeoadjuvantAxillaryNodesAssessmentNotDone?: boolean;
    public postNeoadjuvantAxillaryNodesAssessmentDate?: string;
    public diagnosticSuspisciousAxillary?: number;
    public postAnthracyclineSuspisciousAxillary?: number;
    public postNeoadjuvantChemoSuspisciousAxillary?: number;
    public diagnosticCytologicalAssessment: string;
    public diagnosticCytologicalAssessmentOther: string;
    public postAnthracyclineCytologicalAssessment: string;
    public postAnthracyclineCytologicalAssessmentOther: string;
    public postNeoadjuvantCytologicalAssessment: string;
    public assessmentOfClinicalResponse: string;
    public surgicalOptions: string;
    public surgeryRequired?: boolean;
    public surgeryCompleted?: boolean;
    // @Ignore()
    public surgeryTypes: SurgeryType[];

    public slnb?: boolean;
    public slnbDate?: string;
    public slnbRemoved?: number;
    public nonSlnbRemoved?: number;
    public injectionType: string;
    // @Ignore()
    public slnbResults: SlnbResult[];

    public axillaryDissectionPerformed?: boolean;
    public axillaryDissectionPerformedDate?: string;
    public nodesRemoved?: number;
    public positiveNodes?: number;
    public axillaryRTPlanned?: boolean;
    public residualDiesease: string;
    public histoPathologicType: string;
    public histoPathologicTypeSpecify: string;
    public histologicalGrade: string;
    public lymphovascularInvasion: string;
    public focalityCentricity: string;
    public radialMarginsInvasive: string;
    public radialMarginsInsitu: string;
    public til?: number;
    public primaryTumourDimension1?: number;
    public primaryTumourDimension2?: number;
    public cancerCellularity?: number;
    public percentageInsitu?: number;
    public positiveLymphNodes?: number;
    public largestMetastasisDiameter?: number;
    // @Ignore()
    public adverseEvents: AdverseEvent[];

    // @Ignore()
    public concomitantMedications: ConcomitantMedication[];

    public followUpDate24Month?: string;
    public changes24Month: string;
    // @Ignore()
    public antiCancerTreatments24Month: AntiCancerTreatment[];

    public chemoAdministered24Month: string;
    public chemoAdministeredSpecify24Month: string;
    public commencementDate24Month?: string;
    public completedDate24Month?: string;
    public followUpDate36Month?: string;
    public changes36Month: string;
    // @Ignore()
    public antiCancerTreatments36Month: AntiCancerTreatment36Month[];

    public chemoAdministered36Month: string;
    public chemoAdministeredSpecify36Month: string;
    public commencementDate36Month?: string;
    public completedDate36Month?: string;
    // @Ignore()
    public breastCancerRecurrences: BreastCancerRecurrence[];

    public dateOfDeath?: string;
    public timeOnStudy?: number;
    public reasonOfDeath: string;
    public reasonOfDeathSpecify: string;
    // @Ignore()
    public queries: Query[];

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ParticipantStudySummary>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PISignOffPatient implements IPISignOffPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public investigatorIds: number[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOffPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class PISignOffRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public investigatorIds: number[];
    public piSignOffId?: number;
    public investigatorId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<PISignOffRequestContext>) { (Object as any).assign(this, init); }
}

export enum PISignOffPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewPISignOff = 7,
    ViewPISignOffForm = 8,
    ViewQuery = 9,
    CreatePISignOff = 10,
    CompletePISignOff = 11,
    CancelPISignOff = 12,
    CompletePISignOffForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    CancelQuery = 17,
    ViewAllPatients = 18,
    UpdatePISignOff = 19,
}

export enum InvestigatorType
{
    PrincipalInvestigator = 1,
    CoInvestigator = 2,
}

export class InvestigatorContext
{
    public active?: boolean;
    public type?: InvestigatorType;

    public constructor(init?: Partial<InvestigatorContext>) { (Object as any).assign(this, init); }
}

export class Investigator implements IInvestigator
{
    public id?: number;
    public onTrial: InvestigatorContext[];
    public masterGroups: { [index: number]: InvestigatorContext[]; };
    public coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    public institutions: { [index: number]: InvestigatorContext[]; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Investigator>) { (Object as any).assign(this, init); }
}

export class PISignOff implements IPISignOff
{
    public id?: number;
    public patientId?: number;
    public piSignOffBatchId?: number;
    public formCount?: number;
    public investigatorId?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOff>) { (Object as any).assign(this, init); }
}

export interface IPISignOffGetCollectionByPISignOffBatchId extends IGet
{
    piSignOffBatchId?: number;
}

export interface IPISignOffGetSingleByPISignOffBatchIdAndPatientId extends IGet
{
    piSignOffBatchId?: number;
    patientId?: number;
}

export class PISignOffUpdateOptions extends PISignOffBatchCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<PISignOffUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IPISignOffPostUpdateSignOff extends IPost
{
    metadata: { [index: string]: Object; };
    piSignOffId?: number;
    options: PISignOffUpdateOptions;
}

export interface IPISignOffPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class PISignOffBatch implements IPISignOffBatch
{
    public id?: number;
    public piSignOffDefinitionId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public count?: number;
    public created?: number;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOffBatch>) { (Object as any).assign(this, init); }
}

export interface IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat extends IGet
{
    piSignOffDefinitionId?: number;
    repeat?: number;
}

export interface IQueryGetCollectionByPISignOffId extends IGet
{
    piSignOffId?: number;
}

export enum UpdatedFormPropertyType
{
    Normal = 1,
    DataChange = 2,
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public type?: UpdatedFormPropertyType;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class PISignOffDefinition implements IPISignOffDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<PISignOffDefinition>) { (Object as any).assign(this, init); }
}

export interface IPISignOffForm extends IForm, IHasPatientId
{
    piSignOffId?: number;
    status?: PISignOffStatus;
    dataDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPISignOffPatient extends IPatient
{
    treatmentArm: string;
    investigatorIds: number[];
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IInvestigator extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial: InvestigatorContext[];
    masterGroups: { [index: number]: InvestigatorContext[]; };
    coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    institutions: { [index: number]: InvestigatorContext[]; };
}

export interface IPISignOff extends IDataModel
{
    patientId?: number;
    piSignOffBatchId?: number;
    formCount?: number;
    investigatorId?: number;
    status?: PISignOffStatus;
    completeDate?: string;
    cancelDate?: string;
    dataDate?: string;
}

export interface IPISignOffBatch extends IDataModel
{
    piSignOffDefinitionId?: number;
    status?: PISignOffStatus;
    repeat?: number;
    count?: number;
    created?: number;
    formDefinitionIds: number[];
    completeDate?: string;
    cancelDate?: string;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    piSignOffId?: number;
    propertyName: string;
    parentQueryId?: number;
    repeat?: number;
    question: string;
    response: string;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
    type?: UpdatedFormPropertyType;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IHasCode
{
    code: string;
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IPISignOffDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class CreatePISignOffResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreatePISignOffResponse>) { (Object as any).assign(this, init); }
}

export class SendPISignOffEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendPISignOffEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: PISignOffPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user.
    */
    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form type.
    */
    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form types.
    */
    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adjuvant therapys.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy[];

    public constructor(init?: Partial<AdjuvantTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdjuvantTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse events.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent[];

    public constructor(init?: Partial<AdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatment.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatments.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment[];

    public constructor(init?: Partial<AntiCancerTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AntiCancerTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatment36MonthSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatment36 month.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Month;

    public constructor(init?: Partial<AntiCancerTreatment36MonthSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatment36MonthCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatment36 months.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Month[];

    public constructor(init?: Partial<AntiCancerTreatment36MonthCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatment36MonthValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AntiCancerTreatment36MonthValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrence.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrences.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence[];

    public constructor(init?: Partial<BreastCancerRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastCancerRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical tumour measurement.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical tumour measurements.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement[];

    public constructor(init?: Partial<ClinicalTumourMeasurementCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ClinicalTumourMeasurementValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeoadjuvantTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved neoadjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeoadjuvantTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved neoadjuvant therapys.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy[];

    public constructor(init?: Partial<NeoadjuvantTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeoadjuvantTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NeoadjuvantTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiologicalTumourMeasurementSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved radiological tumour measurement.
    */
    // @DataMember(Order=2)
    public data: RadiologicalTumourMeasurement;

    public constructor(init?: Partial<RadiologicalTumourMeasurementSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiologicalTumourMeasurementCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved radiological tumour measurements.
    */
    // @DataMember(Order=2)
    public data: RadiologicalTumourMeasurement[];

    public constructor(init?: Partial<RadiologicalTumourMeasurementCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiologicalTumourMeasurementValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<RadiologicalTumourMeasurementValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb results.
    */
    // @DataMember(Order=2)
    public data: SlnbResult[];

    public constructor(init?: Partial<SlnbResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SlnbResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery type.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery types.
    */
    // @DataMember(Order=2)
    public data: SurgeryType[];

    public constructor(init?: Partial<SurgeryTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryTypeValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryTypeValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummarySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved participant study summary.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummaryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved participant study summarys.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary[];

    public constructor(init?: Partial<ParticipantStudySummaryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummaryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ParticipantStudySummaryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating groups.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off definition.
    */
    // @DataMember(Order=2)
    public configuration: PISignOffDefinition;

    public constructor(init?: Partial<PISignOffDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off definitions.
    */
    // @DataMember(Order=2)
    public configuration: PISignOffDefinition[];

    public constructor(init?: Partial<PISignOffDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved investigator.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved investigators.
    */
    // @DataMember(Order=2)
    public data: Investigator[];

    public constructor(init?: Partial<InvestigatorCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InvestigatorValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign offs.
    */
    // @DataMember(Order=2)
    public data: PISignOff[];

    public constructor(init?: Partial<PISignOffCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<PISignOffValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batchs.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch[];

    public constructor(init?: Partial<PISignOffBatchCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffBatchValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCreationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved querys.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformpropertys.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/test")
export class Test extends NativeTypesBase implements IGet
{

    public constructor(init?: Partial<Test>) { super(init); (Object as any).assign(this, init); }
}

export class CreatePISignOff implements IReturn<CreatePISignOffResponse>
{
    public responseStatus: ResponseStatus;
    public piSignOffBatchId?: number;
    public patientId?: number;
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<CreatePISignOff>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePISignOffResponse(); }
    public getTypeName() { return 'CreatePISignOff'; }
}

export class SendPISignOffCompleteEmail implements IReturn<SendPISignOffEmailResponse>
{
    public piSignOffId?: number;

    public constructor(init?: Partial<SendPISignOffCompleteEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendPISignOffCompleteEmail'; }
}

export class QueueAvailablePISignOffEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailablePISignOffEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueueEmailJobsResponse(); }
    public getTypeName() { return 'QueueAvailablePISignOffEmails'; }
}

export class SendPISignOffsAvailableEmails implements IReturn<SendPISignOffEmailResponse>
{
    public investigatorId?: number;

    public constructor(init?: Partial<SendPISignOffsAvailableEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendPISignOffsAvailableEmails'; }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueueEmailJobsResponse(); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendPISignOffEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
}

export class QueueInvestigatorQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInvestigatorQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueueEmailJobsResponse(); }
    public getTypeName() { return 'QueueInvestigatorQuerySummaryEmails'; }
}

// @Route("/emails/investigator-query-summary/{InvestigatorId}")
export class SendInvestigatorQuerySummaryEmail implements IReturn<SendPISignOffEmailResponse>
{
    public investigatorId?: number;

    public constructor(init?: Partial<SendInvestigatorQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendInvestigatorQuerySummaryEmail'; }
}

export class SendNewQueryAddedEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAddedEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendNewQueryAddedEmail'; }
}

export class SendNewQueryAsResponseEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAsResponseEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendNewQueryAsResponseEmail'; }
}

export class SendQueryClosedEarlyEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryClosedEarlyEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendQueryClosedEarlyEmail'; }
}

export class SendQueryResponseEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryResponseEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendPISignOffEmailResponse(); }
    public getTypeName() { return 'SendQueryResponseEmail'; }
}

// @Route("/pi-signoff/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

// @Route("/pi-signoff/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'PostUploadFile'; }
}

// @Route("/pi-signoff/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'GetUploadInfo'; }
}

// @Route("/pi-signoff/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetUpload'; }
}

// @Route("/pi-signoff/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadUpload'; }
}

// @Route("/pi-signoff/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'DeleteUpload'; }
}

/**
* User
*/
// @Route("/pi-signoff/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserSingleResponse(); }
    public getTypeName() { return 'UserGetSingle'; }
}

/**
* Lookup
*/
// @Route("/pi-signoff/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupCollectionResponse(); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
}

// @Route("/pi-signoff/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormMetadataResponse(); }
    public getTypeName() { return 'GetFormMetadata'; }
}

/**
* Form Type
*/
// @Route("/pi-signoff/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
}

/**
* Form Type
*/
// @Route("/pi-signoff/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeCollectionResponse(); }
    public getTypeName() { return 'FormTypeGetCollection'; }
}

/**
* Form Type
*/
// @Route("/pi-signoff/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
}

/**
* Form Definition
*/
// @Route("/pi-signoff/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
}

/**
* Form Definition
*/
// @Route("/pi-signoff/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
}

/**
* Form Definition
*/
// @Route("/pi-signoff/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyGetSingleById implements IReturn<AdjuvantTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adjuvant therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdjuvantTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyGetSingleById'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/collection", "GET")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyGetCollection implements IReturn<AdjuvantTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdjuvantTherapyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapyCollectionResponse(); }
    public getTypeName() { return 'AdjuvantTherapyGetCollection'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/create", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostCreate implements IReturn<AdjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the adjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostCreate'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/save", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostSave implements IReturn<AdjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the adjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adjuvant therapy to save.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostSave'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/validate", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostValidate implements IReturn<AdjuvantTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the adjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adjuvant therapy to validate.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapyValidationResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostValidate'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyGetSingleByPISignOffIdAndRepeat implements IReturn<AdjuvantTherapySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the adjuvant therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdjuvantTherapyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/pi-signoff/form/adjuvant-therapy/single/update", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostUpdateById implements IReturn<AdjuvantTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdjuvantTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostUpdateById'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleById implements IReturn<AdverseEventSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventSingleResponse(); }
    public getTypeName() { return 'AdverseEventGetSingleById'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/collection", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollection implements IReturn<AdverseEventCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventCollectionResponse(); }
    public getTypeName() { return 'AdverseEventGetCollection'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/create", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostCreate implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adverse event.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventSingleResponse(); }
    public getTypeName() { return 'AdverseEventPostCreate'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/save", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSave implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event to save.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventSingleResponse(); }
    public getTypeName() { return 'AdverseEventPostSave'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/validate", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidate implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The metadata to use when validating the adverse event.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventValidationResponse(); }
    public getTypeName() { return 'AdverseEventPostValidate'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByPISignOffIdAndRepeat implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventSingleResponse(); }
    public getTypeName() { return 'AdverseEventGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - AdverseEvent
*/
// @Route("/pi-signoff/form/adverse-event/single/update", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostUpdateById implements IReturn<AdverseEventSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventSingleResponse(); }
    public getTypeName() { return 'AdverseEventPostUpdateById'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentGetSingleById implements IReturn<AntiCancerTreatmentSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the anti cancer treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AntiCancerTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentGetSingleById'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/collection", "GET")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentGetCollection implements IReturn<AntiCancerTreatmentCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AntiCancerTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentCollectionResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentGetCollection'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/create", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostCreate implements IReturn<AntiCancerTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the anti cancer treatment.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostCreate'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/save", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostSave implements IReturn<AntiCancerTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment to save.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostSave'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/validate", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostValidate implements IReturn<AntiCancerTreatmentValidationResponse>
{
    /**
    * The metadata to use when validating the anti cancer treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment to validate.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentValidationResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostValidate'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentGetSingleByPISignOffIdAndRepeat implements IReturn<AntiCancerTreatmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the anti cancer treatment to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AntiCancerTreatmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/pi-signoff/form/anti-cancer-treatment/single/update", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostUpdateById implements IReturn<AntiCancerTreatmentSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AntiCancerTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostUpdateById'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/single/id/{Id}", "GET")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthGetSingleById implements IReturn<AntiCancerTreatment36MonthSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the anti cancer treatment36 month to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AntiCancerTreatment36MonthGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthGetSingleById'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/collection", "GET")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthCollectionResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthGetCollection implements IReturn<AntiCancerTreatment36MonthCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AntiCancerTreatment36MonthGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthCollectionResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthGetCollection'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/create", "POST")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthPostCreate implements IReturn<AntiCancerTreatment36MonthSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment36 month.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the anti cancer treatment36 month.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Month;

    public constructor(init?: Partial<AntiCancerTreatment36MonthPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthPostCreate'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/save", "POST")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthPostSave implements IReturn<AntiCancerTreatment36MonthSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment36 month.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment36 month to save.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Month;

    public constructor(init?: Partial<AntiCancerTreatment36MonthPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthPostSave'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/validate", "POST")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthValidationResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthPostValidate implements IReturn<AntiCancerTreatment36MonthValidationResponse>
{
    /**
    * The metadata to use when validating the anti cancer treatment36 month.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment36 month to validate.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Month;

    public constructor(init?: Partial<AntiCancerTreatment36MonthPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthValidationResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthPostValidate'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthGetSingleByPISignOffIdAndRepeat implements IReturn<AntiCancerTreatment36MonthSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the anti cancer treatment36 month to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AntiCancerTreatment36MonthGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - AntiCancerTreatment36Month
*/
// @Route("/pi-signoff/form/anti-cancer-treatment36-month/single/update", "POST")
// @Api(Description="Form - AntiCancerTreatment36Month")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthPostUpdateById implements IReturn<AntiCancerTreatment36MonthSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AntiCancerTreatment36MonthPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthPostUpdateById'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetSingleById implements IReturn<BreastCancerRecurrenceSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast cancer recurrence to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrenceGetSingleById'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/collection", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetCollection implements IReturn<BreastCancerRecurrenceCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceCollectionResponse(); }
    public getTypeName() { return 'BreastCancerRecurrenceGetCollection'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/create", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostCreate implements IReturn<BreastCancerRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast cancer recurrence.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostCreate'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/save", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostSave implements IReturn<BreastCancerRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence to save.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostSave'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/validate", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostValidate implements IReturn<BreastCancerRecurrenceValidationResponse>
{
    /**
    * The metadata to use when validating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence to validate.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceValidationResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostValidate'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetSingleByPISignOffIdAndRepeat implements IReturn<BreastCancerRecurrenceSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the breast cancer recurrence to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrenceGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/pi-signoff/form/breast-cancer-recurrence/single/update", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostUpdateById implements IReturn<BreastCancerRecurrenceSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastCancerRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostUpdateById'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/single/id/{Id}", "GET")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementGetSingleById implements IReturn<ClinicalTumourMeasurementSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the clinical tumour measurement to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementGetSingleById'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/collection", "GET")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementGetCollection implements IReturn<ClinicalTumourMeasurementCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCollectionResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementGetCollection'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/create", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostCreate implements IReturn<ClinicalTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the clinical tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the clinical tumour measurement.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostCreate'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/save", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostSave implements IReturn<ClinicalTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the clinical tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The clinical tumour measurement to save.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostSave'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/validate", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostValidate implements IReturn<ClinicalTumourMeasurementValidationResponse>
{
    /**
    * The metadata to use when validating the clinical tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The clinical tumour measurement to validate.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementValidationResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostValidate'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementGetSingleByPISignOffIdAndRepeat implements IReturn<ClinicalTumourMeasurementSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the clinical tumour measurement to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/pi-signoff/form/clinical-tumour-measurement/single/update", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostUpdateById implements IReturn<ClinicalTumourMeasurementSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ClinicalTumourMeasurementPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostUpdateById'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/create", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostCreate implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the concomitant medication.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationPostCreate'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/save", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The metadata to use when validating the concomitant medication.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByPISignOffIdAndRepeat implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - ConcomitantMedication
*/
// @Route("/pi-signoff/form/concomitant-medication/single/update", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostUpdateById implements IReturn<ConcomitantMedicationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationPostUpdateById'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyGetSingleById implements IReturn<NeoadjuvantTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the neoadjuvant therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NeoadjuvantTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyGetSingleById'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/collection", "GET")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyGetCollection implements IReturn<NeoadjuvantTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NeoadjuvantTherapyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapyCollectionResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyGetCollection'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/create", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostCreate implements IReturn<NeoadjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the neoadjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the neoadjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostCreate'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/save", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostSave implements IReturn<NeoadjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the neoadjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The neoadjuvant therapy to save.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostSave'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/validate", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostValidate implements IReturn<NeoadjuvantTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the neoadjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The neoadjuvant therapy to validate.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapyValidationResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostValidate'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyGetSingleByPISignOffIdAndRepeat implements IReturn<NeoadjuvantTherapySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the neoadjuvant therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NeoadjuvantTherapyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/pi-signoff/form/neoadjuvant-therapy/single/update", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostUpdateById implements IReturn<NeoadjuvantTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NeoadjuvantTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostUpdateById'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/single/id/{Id}", "GET")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementGetSingleById implements IReturn<RadiologicalTumourMeasurementSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the radiological tumour measurement to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<RadiologicalTumourMeasurementGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementGetSingleById'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/collection", "GET")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementCollectionResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementGetCollection implements IReturn<RadiologicalTumourMeasurementCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<RadiologicalTumourMeasurementGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementCollectionResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementGetCollection'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/create", "POST")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementPostCreate implements IReturn<RadiologicalTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the radiological tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the radiological tumour measurement.
    */
    // @DataMember(Order=2)
    public data: RadiologicalTumourMeasurement;

    public constructor(init?: Partial<RadiologicalTumourMeasurementPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementPostCreate'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/save", "POST")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementPostSave implements IReturn<RadiologicalTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the radiological tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The radiological tumour measurement to save.
    */
    // @DataMember(Order=2)
    public data: RadiologicalTumourMeasurement;

    public constructor(init?: Partial<RadiologicalTumourMeasurementPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementPostSave'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/validate", "POST")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementValidationResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementPostValidate implements IReturn<RadiologicalTumourMeasurementValidationResponse>
{
    /**
    * The metadata to use when validating the radiological tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The radiological tumour measurement to validate.
    */
    // @DataMember(Order=2)
    public data: RadiologicalTumourMeasurement;

    public constructor(init?: Partial<RadiologicalTumourMeasurementPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementValidationResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementPostValidate'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementGetSingleByPISignOffIdAndRepeat implements IReturn<RadiologicalTumourMeasurementSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the radiological tumour measurement to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<RadiologicalTumourMeasurementGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - RadiologicalTumourMeasurement
*/
// @Route("/pi-signoff/form/radiological-tumour-measurement/single/update", "POST")
// @Api(Description="Form - RadiologicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiologicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class RadiologicalTumourMeasurementPostUpdateById implements IReturn<RadiologicalTumourMeasurementSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<RadiologicalTumourMeasurementPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new RadiologicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'RadiologicalTumourMeasurementPostUpdateById'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/single/id/{Id}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleById implements IReturn<SlnbResultSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the slnb result to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SlnbResultGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultGetSingleById'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/collection", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetCollection implements IReturn<SlnbResultCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SlnbResultGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultCollectionResponse(); }
    public getTypeName() { return 'SlnbResultGetCollection'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/create", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostCreate implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultPostCreate'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/save", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostSave implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to save.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultPostSave'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/validate", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostValidate implements IReturn<SlnbResultValidationResponse>
{
    /**
    * The metadata to use when validating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to validate.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultValidationResponse(); }
    public getTypeName() { return 'SlnbResultPostValidate'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleByPISignOffIdAndRepeat implements IReturn<SlnbResultSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the slnb result to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SlnbResultGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - SlnbResult
*/
// @Route("/pi-signoff/form/slnb-result/single/update", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostUpdateById implements IReturn<SlnbResultSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SlnbResultPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultPostUpdateById'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/single/id/{Id}", "GET")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypeGetSingleById implements IReturn<SurgeryTypeSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the surgery type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypeGetSingleById'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/collection", "GET")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypeGetCollection implements IReturn<SurgeryTypeCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeCollectionResponse(); }
    public getTypeName() { return 'SurgeryTypeGetCollection'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/create", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostCreate implements IReturn<SurgeryTypeSingleResponse>
{
    /**
    * The metadata to use when creating the surgery type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgery type.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypePostCreate'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/save", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostSave implements IReturn<SurgeryTypeSingleResponse>
{
    /**
    * The metadata to use when creating the surgery type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery type to save.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypePostSave'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/validate", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostValidate implements IReturn<SurgeryTypeValidationResponse>
{
    /**
    * The metadata to use when validating the surgery type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery type to validate.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeValidationResponse(); }
    public getTypeName() { return 'SurgeryTypePostValidate'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypeGetSingleByPISignOffIdAndRepeat implements IReturn<SurgeryTypeSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the surgery type to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryTypeGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypeGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - SurgeryType
*/
// @Route("/pi-signoff/form/surgery-type/single/update", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostUpdateById implements IReturn<SurgeryTypeSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgeryTypePostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypePostUpdateById'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/single/id/{Id}", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetSingleById implements IReturn<ParticipantStudySummarySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the participant study summary to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryGetSingleById'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/collection", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetCollection implements IReturn<ParticipantStudySummaryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummaryCollectionResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryGetCollection'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/create", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostCreate implements IReturn<ParticipantStudySummarySingleResponse>
{
    /**
    * The metadata to use when creating the participant study summary.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the participant study summary.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryPostCreate'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/save", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostSave implements IReturn<ParticipantStudySummarySingleResponse>
{
    /**
    * The metadata to use when creating the participant study summary.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The participant study summary to save.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryPostSave'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/validate", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostValidate implements IReturn<ParticipantStudySummaryValidationResponse>
{
    /**
    * The metadata to use when validating the participant study summary.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The participant study summary to validate.
    */
    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummaryValidationResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryPostValidate'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat implements IReturn<ParticipantStudySummarySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    /**
    * The id of the PI Sign-Off.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    /**
    * The repeat of the participant study summary to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat'; }
}

/**
* Form - ParticipantStudySummary
*/
// @Route("/pi-signoff/form/participant-study-summary/single/update", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostUpdateById implements IReturn<ParticipantStudySummarySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ParticipantStudySummaryPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
    public getTypeName() { return 'ParticipantStudySummaryPostUpdateById'; }
}

// @Route("/pi-signoff/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFile'; }
}

// @Route("/pi-signoff/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadFile'; }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupCollectionResponse(); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to save.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupPostSave'; }
}

/**
* Master Group
*/
// @Route("/pi-signoff/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    /**
    * The metadata to use when validating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to validate.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupValidationResponse(); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the coordinating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to save.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
}

/**
* Coordinating Group
*/
// @Route("/pi-signoff/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    /**
    * The metadata to use when validating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to validate.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
}

/**
* Country
*/
// @Route("/pi-signoff/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryGetSingleById'; }
}

/**
* Country
*/
// @Route("/pi-signoff/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountryCollectionResponse(); }
    public getTypeName() { return 'CountryGetCollection'; }
}

/**
* Country
*/
// @Route("/pi-signoff/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryPostCreate'; }
}

/**
* Country
*/
// @Route("/pi-signoff/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to save.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryPostSave'; }
}

/**
* Country
*/
// @Route("/pi-signoff/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    /**
    * The metadata to use when validating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to validate.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountryValidationResponse(); }
    public getTypeName() { return 'CountryPostValidate'; }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionCollectionResponse(); }
    public getTypeName() { return 'InstitutionGetCollection'; }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionPostCreate'; }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to save.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionPostSave'; }
}

/**
* Institution
*/
// @Route("/pi-signoff/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    /**
    * The metadata to use when validating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to validate.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionValidationResponse(); }
    public getTypeName() { return 'InstitutionPostValidate'; }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleById'; }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/collection", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientCollectionResponse(); }
    public getTypeName() { return 'PatientGetCollection'; }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/create", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the patient.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostCreate'; }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/save", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to save.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostSave'; }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/validate", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    /**
    * The metadata to use when validating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientValidationResponse(); }
    public getTypeName() { return 'PatientPostValidate'; }
}

/**
* Patient - PISignOffPatient
*/
// @Route("/pi-signoff/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
}

// @Route("/pi-signoff/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
}

// @Route("/pi-signoff/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: PISignOffRequestContext;
    public permissions: PISignOffPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostHasPermissionsResponse(); }
    public getTypeName() { return 'PostHasPermissions'; }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleById implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the pi sign off definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleById'; }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/collection", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetCollection implements IReturn<PISignOffDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffDefinitionCollectionResponse(); }
    public getTypeName() { return 'PISignOffDefinitionGetCollection'; }
}

/**
* PI Sign-off Definition
*/
// @Route("/pi-signoff/pi-sign-off-definition/single/code/{Code}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleByCode implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the pi sign off definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleByCode'; }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/single/id/{Id}", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetSingleById implements IReturn<InvestigatorSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the investigator to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InvestigatorGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new InvestigatorSingleResponse(); }
    public getTypeName() { return 'InvestigatorGetSingleById'; }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/collection", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetCollection implements IReturn<InvestigatorCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InvestigatorGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new InvestigatorCollectionResponse(); }
    public getTypeName() { return 'InvestigatorGetCollection'; }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/create", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostCreate implements IReturn<InvestigatorSingleResponse>
{
    /**
    * The metadata to use when creating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the investigator.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InvestigatorSingleResponse(); }
    public getTypeName() { return 'InvestigatorPostCreate'; }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/save", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostSave implements IReturn<InvestigatorSingleResponse>
{
    /**
    * The metadata to use when creating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The investigator to save.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new InvestigatorSingleResponse(); }
    public getTypeName() { return 'InvestigatorPostSave'; }
}

/**
* Investigator
*/
// @Route("/pi-signoff/investigator/validate", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostValidate implements IReturn<InvestigatorValidationResponse>
{
    /**
    * The metadata to use when validating the investigator.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The investigator to validate.
    */
    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InvestigatorValidationResponse(); }
    public getTypeName() { return 'InvestigatorPostValidate'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleById implements IReturn<PISignOffSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the pi sign off to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffSingleResponse(); }
    public getTypeName() { return 'PISignOffGetSingleById'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollection implements IReturn<PISignOffCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffCollectionResponse(); }
    public getTypeName() { return 'PISignOffGetCollection'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/create", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostCreate implements IReturn<PISignOffSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffSingleResponse(); }
    public getTypeName() { return 'PISignOffPostCreate'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/save", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostSave implements IReturn<PISignOffSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off to save.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffSingleResponse(); }
    public getTypeName() { return 'PISignOffPostSave'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/validate", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostValidate implements IReturn<PISignOffValidationResponse>
{
    /**
    * The metadata to use when validating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffValidationResponse(); }
    public getTypeName() { return 'PISignOffPostValidate'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection/pi-signoff-batch-id/{PISignOffBatchId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollectionByPISignOffBatchId implements IReturn<PISignOffCollectionResponse>, IPISignOffGetCollectionByPISignOffBatchId
{
    /**
    * The PI Sign-Off Batch ID of the pi sign off to retrieve.
    */
    // @DataMember(Order=1)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetCollectionByPISignOffBatchId>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffCollectionResponse(); }
    public getTypeName() { return 'PISignOffGetCollectionByPISignOffBatchId'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/single/pi-signoff-batch-id-patient-id/{PISignOffBatchId}/{PatientId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleByPISignOffBatchIdAndPatientId implements IReturn<PISignOffSingleResponse>, IPISignOffGetSingleByPISignOffBatchIdAndPatientId
{
    /**
    * The ID of the related Patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The ID of the related PI Sign-Off Batch.
    */
    // @DataMember(Order=2)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetSingleByPISignOffBatchIdAndPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffSingleResponse(); }
    public getTypeName() { return 'PISignOffGetSingleByPISignOffBatchIdAndPatientId'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/update-signoff", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostUpdateSignOff implements IReturn<PISignOffSingleResponse>, IPISignOffPostUpdateSignOff
{
    /**
    * The metadata to use when creating the pi sign off.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the pi sign off.
    */
    // @DataMember(Order=2)
    public piSignOffId?: number;

    /**
    * The options to use when creating the pi sign off.
    */
    // @DataMember(Order=2)
    public options: PISignOffUpdateOptions;

    public constructor(init?: Partial<PISignOffPostUpdateSignOff>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffSingleResponse(); }
    public getTypeName() { return 'PISignOffPostUpdateSignOff'; }
}

/**
* PI Sign-off
*/
// @Route("/pi-signoff/pi-sign-off/collection/validate-forms", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostFormValidationCollectionById implements IReturn<PISignOffValidationCollectionResponse>, IPISignOffPostFormValidationCollectionById
{
    /**
    * The metadata to use when validating the pi sign off forms.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the pi sign off to medical review.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffValidationCollectionResponse(); }
    public getTypeName() { return 'PISignOffPostFormValidationCollectionById'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/single/id/{Id}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleById implements IReturn<PISignOffBatchSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the pi sign off batch to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
    public getTypeName() { return 'PISignOffBatchGetSingleById'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/collection", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetCollection implements IReturn<PISignOffBatchCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffBatchGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchCollectionResponse(); }
    public getTypeName() { return 'PISignOffBatchGetCollection'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/create", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreate implements IReturn<PISignOffBatchSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
    public getTypeName() { return 'PISignOffBatchPostCreate'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/save", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostSave implements IReturn<PISignOffBatchSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off batch to save.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
    public getTypeName() { return 'PISignOffBatchPostSave'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/validate", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostValidate implements IReturn<PISignOffBatchValidationResponse>
{
    /**
    * The metadata to use when validating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The pi sign off batch to validate.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchValidationResponse(); }
    public getTypeName() { return 'PISignOffBatchPostValidate'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/create-signoff", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreateSignOff implements IReturn<PISignOffBatchCreationSingleResponse>
{
    /**
    * The metadata to use when creating the pi sign off batch.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the pi sign off batch.
    */
    // @DataMember(Order=2)
    public data: PISignOffBatch;

    /**
    * The options to use when creating the pi sign off batch.
    */
    // @DataMember(Order=2)
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<PISignOffBatchPostCreateSignOff>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchCreationSingleResponse(); }
    public getTypeName() { return 'PISignOffBatchPostCreateSignOff'; }
}

/**
* PI Sign-Off Batch
*/
// @Route("/pi-signoff/pi-sign-off-batch/single/pi-signoff-batch-id-patient-id/{PISignOffDefinitionId}/{Repeat}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat implements IReturn<PISignOffBatchSingleResponse>, IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat
{
    /**
    * The Definition ID of the PI Sign-Off Batch.
    */
    // @DataMember(Order=1)
    public piSignOffDefinitionId?: number;

    /**
    * The Repeat of the related PI Sign-Off Batch.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
    public getTypeName() { return 'PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the query to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new QuerySingleResponse(); }
    public getTypeName() { return 'QueryGetSingleById'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryCollectionResponse(); }
    public getTypeName() { return 'QueryGetCollection'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new QuerySingleResponse(); }
    public getTypeName() { return 'QueryPostCreate'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new QuerySingleResponse(); }
    public getTypeName() { return 'QueryPostSave'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    /**
    * The metadata to use when validating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to validate.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryValidationResponse(); }
    public getTypeName() { return 'QueryPostValidate'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/collection/pi-signoff-id/{PISignOffId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByPISignOffId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByPISignOffId
{
    /**
    * The ID of the PI sign-off to retrieve query for.
    */
    // @DataMember(Order=1)
    public piSignOffId?: number;

    public constructor(init?: Partial<QueryGetCollectionByPISignOffId>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryCollectionResponse(); }
    public getTypeName() { return 'QueryGetCollectionByPISignOffId'; }
}

/**
* Query
*/
// @Route("/pi-signoff/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryCollectionResponse(); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to save.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    /**
    * The metadata to use when validating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to validate.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/pi-signoff/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    /**
    * The Form Definition ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    /**
    * The Form ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
}

