import { Accordion, AccordionDetails, AccordionSummary, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BooleanDisplay, DateDisplay, FieldProvider, FormLabel, getParentPropertyPath, GroupedFields, IGroupedFieldStyleProps, Input, NumericDisplay, TextDisplay } from '@ngt/forms';
import { Condition, ContextFormTitle, ContextPISignOffForm, GroupedField, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, InputOnlyField } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent } from 'react';
import { AntiCancerTreatment, AntiCancerTreatment36Month, BreastCancerRecurrence, ParticipantStudySummary as RecurrenceSurvivalDto } from '../../apis/dtos';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { ConditionMode, useFieldState, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface ISurvivalFollowUpAndRecurrenceProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    alert: {
        padding: theme.spacing(2)
    },
    subtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const has24MonthFollowup: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.followUpDate24Month;
const hasNo24MonthFollowup: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.followUpDate24Month;
const has36MonthFollowup: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.followUpDate36Month;
const hasNo36MonthFollowup: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.followUpDate36Month;

const hasNo24MonthTreatment: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.antiCancerTreatments24Month || value?.antiCancerTreatments24Month?.length === 0;
const has24MonthTreatment: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.antiCancerTreatments24Month && value?.antiCancerTreatments24Month?.length > 0;

// const hasNo36MonthTreatment: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.antiCancerTreatments36Month || value?.antiCancerTreatments36Month?.length === 0;
const has36MonthTreatment: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.antiCancerTreatments36Month && value?.antiCancerTreatments36Month?.length > 0;

const hasNoBreastCancerRecurrence: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.breastCancerRecurrences || value?.breastCancerRecurrences?.length === 0;
const hasBreastCancerRecurrence: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.breastCancerRecurrences && value?.breastCancerRecurrences?.length > 0;

const hasChemo24MonthSpec: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.chemoAdministeredSpecify24Month;
const hasChemo24Month: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.chemoAdministered24Month;
const hasChemo36MonthSpec: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.chemoAdministeredSpecify36Month;
const hasChemo36Month: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.chemoAdministered36Month;

const hasDeathForm: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.dateOfDeath;
const hasNoDeathForm: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.dateOfDeath;

const timeOnStudySingle: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.timeOnStudy && value?.timeOnStudy === 0;
const timeOnStudyMultiple: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.timeOnStudy && value?.timeOnStudy > 0;

const hasOtherDeathReason: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !!value?.reasonOfDeathSpecify;
const hasNoOtherDeathReason: ICondition<RecurrenceSurvivalDto> = ({ formState: { value } = {} }) => !value?.reasonOfDeathSpecify;

const conditionSubscription = { value: true };

const breatCancerRecurrenceColumns: IGroupedSubformGridCell<BreastCancerRecurrence>[] = [
    {
        name: 'type',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dateSuspicion',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'dateConfirmation',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'timeOnStudy',
        content: (
            <Input component={NumericDisplay}
                suffix="month(s)" />
        )
    },
    {
        name: 'typeOfConfirmation',
        content: (
            <Input component={TextDisplay} />
        )
    }
];


const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = (value: string) => value ? 'Ongoing' : null;

const antiCancerTreatmentColumns: IGroupedSubformGridCell<AntiCancerTreatment>[] = [
    {
        name: 'treatmentSpecify',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="treatmentSpecify"
                elseName="treatment"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'treatment'
                }
            ]
        }
    },
    {
        name: 'commencementDate',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'completedDate',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="completedDate"
                elseName="ongoing"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ongoing'
                }
            ]
        }
    }
];

const antiCancerTreatment36MonthsColumns: IGroupedSubformGridCell<AntiCancerTreatment36Month>[] = [
    {
        name: 'treatmentSpecify',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="treatmentSpecify"
                elseName="treatment"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'treatment'
                }
            ]
        }
    },
    {
        name: 'commencementDate',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'completedDate',
        content: (
            <Input
                component={IfElseDisplay}
                ifName="completedDate"
                elseName="ongoing"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ongoing'
                }
            ]
        }
    }
];

const SurvivalFollowUpAndRecurrence: FunctionComponent<ISurvivalFollowUpAndRecurrenceProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.subtitle}>
                        Survival Follow-Up & Recurrence
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields
                        color="None"
                    >
                        <Typography variant="h3" className={classes.heading}>
                            24 Month Survival Follow Up
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={has24MonthFollowup}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="followUpDate24Month"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="changes24Month"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />

                            <Typography variant="h4" className={classes.subheading}>
                                Anti-Cancer Treatment
                            </Typography>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={has24MonthTreatment}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedSubformGrid
                                    type={AntiCancerTreatment}
                                    name="antiCancerTreatments24Month"
                                    columns={antiCancerTreatmentColumns}
                                    disabled
                                />
                            </Condition>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={hasNo24MonthTreatment}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <div className={classes.alert}>
                                    <BasicAlert
                                        title="No 24 Month Anti-Cancer Treatments associated with this patient"
                                        description="There are no 24 Month Anti Cancer Treatments associated with this patient."
                                        icon={faInfoCircle}
                                        severity="info"
                                    />
                                </div>
                            </Condition>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={hasChemo24Month}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="chemoAdministered24Month"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <Condition
                                    type={RecurrenceSurvivalDto}
                                    condition={hasChemo24MonthSpec}
                                    subscription={conditionSubscription}
                                    mode={ConditionMode.Show}
                                >
                                    <GroupedField
                                        name="chemoAdministeredSpecify24Month"
                                        component={TextDisplay}
                                        GroupStyleProps={groupStyleProps}
                                    />
                                </Condition>
                                <GroupedField
                                    name="commencementDate24Month"
                                    component={DateDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <GroupedField
                                    name="completedDate24Month"
                                    component={DateDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNo24MonthFollowup}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No 24 Month Follow up associated with this patient"
                                    description="There is no 24 Month Follow up associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields
                        color="None"
                    >
                        <Typography variant="h3" className={classes.heading}>
                            36 Month Survival Follow Up
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={has36MonthFollowup}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >

                            <GroupedField
                                name="followUpDate36Month"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <GroupedField
                                name="changes36Month"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={has36MonthTreatment}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <Typography variant="h4" className={classes.subheading}>
                                    Anti-Cancer Treatment
                                </Typography>
                                <GroupedSubformGrid
                                    type={AntiCancerTreatment36Month}
                                    name="antiCancerTreatments36Month"
                                    columns={antiCancerTreatment36MonthsColumns}
                                    disabled
                                />
                            </Condition>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={hasChemo36Month}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="chemoAdministered36Month"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <Condition
                                    type={RecurrenceSurvivalDto}
                                    condition={hasChemo36MonthSpec}
                                    subscription={conditionSubscription}
                                    mode={ConditionMode.Show}
                                >
                                    <GroupedField
                                        name="chemoAdministeredSpecify36Month"
                                        component={TextDisplay}
                                        GroupStyleProps={groupStyleProps}
                                    />
                                </Condition>
                                <GroupedField
                                    name="commencementDate36Month"
                                    component={DateDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                                <GroupedField
                                    name="completedDate36Month"
                                    component={DateDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNo36MonthFollowup}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No 36 Month Follow up associated with this patient"
                                    description="There is no 36 Month Follow up associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields
                        color="None"
                    >
                        <Typography variant="h3" className={classes.heading}>
                            Breast Cancer Recurrence
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasBreastCancerRecurrence}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={BreastCancerRecurrence}
                                name="breastCancerRecurrences"
                                columns={breatCancerRecurrenceColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNoBreastCancerRecurrence}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Breast Cancer Recurrence associated with this patient"
                                    description="There is no Breast Cancer Recurrence associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    <GroupedFields
                        color="None"
                    >
                        <Typography variant="h3" className={classes.heading}>
                            Death Report Form
                        </Typography>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasDeathForm}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="dateOfDeath"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={timeOnStudySingle}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="timeOnStudy"
                                    component={NumericDisplay}
                                    suffix=" month"
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={timeOnStudyMultiple}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="timeOnStudy"
                                    component={NumericDisplay}
                                    suffix=" months"
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={hasNoOtherDeathReason}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="reasonOfDeath"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                            <Condition
                                type={RecurrenceSurvivalDto}
                                condition={hasOtherDeathReason}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedField
                                    name="reasonOfDeathSpecify"
                                    component={TextDisplay}
                                    GroupStyleProps={groupStyleProps}
                                />
                            </Condition>
                        </Condition>
                        <Condition
                            type={RecurrenceSurvivalDto}
                            condition={hasNoDeathForm}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Death Form associated with this patient"
                                    description="There is no Death Form associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SurvivalFollowUpAndRecurrence;
